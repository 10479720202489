import HighlightOff from "@mui/icons-material/HighlightOff";
import { type BoxProps, Box, IconButton, Typography } from "@mui/material";
import { position } from "polished";
import { memo, useCallback, useState } from "react";

const WaterMark = memo<BoxProps>((boxProps) => {
  const [display, setDisplay] = useState(true);
  const handleClick = useCallback(() => {
    setDisplay(false);
  }, [setDisplay]);
  if (!display) return null;
  return (
    <Box position="relative" zIndex={1}>
      <Box
        bgcolor="error.main"
        borderRadius={2}
        boxShadow={3}
        sx={{
          opacity: 0.9,
          transform: "translate3d(50%, 40px, 0)",
          ...position("absolute", 0, "50%", null, null),
        }}
        width={300}
        {...boxProps}
      >
        <IconButton
          onClick={handleClick}
          sx={{ color: "white", ...position("absolute", 0, 0, null, null) }}
        >
          <HighlightOff />
        </IconButton>
        <Typography color="error.contrastText" p={2} textAlign="center" variant="h4">
          Preview
        </Typography>
      </Box>
    </Box>
  );
});
WaterMark.displayName = "WaterMark";
export default WaterMark;
