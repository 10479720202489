import { Box, Typography, useTheme } from "@mui/material";
import type { FC, MouseEvent } from "react";
import { PhoneIcon, PostalMailIcon } from "../ui";

type GenericSolutionCardProps = {
  callbackFirstLine?: (event: MouseEvent<Element>) => void;
  className?: string;
  title: string;
  type: "pencil" | "phone";
  paragraphs: string[];
};

const GenericSolutionCard: FC<GenericSolutionCardProps> = ({
  callbackFirstLine,
  className,
  title,
  type,
  paragraphs,
}) => {
  const iconLeft = type === "phone" ? <PhoneIcon /> : <PostalMailIcon />;
  const theme = useTheme();

  //TODO : put fontSize & media query in theme.
  //TODO : Check if icon color is still customizable
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      margin="0 auto"
      pt={theme.spacing(4)}
      width="100%"
      height="100vh"
      className={className}
    >
      <Typography
        variant="h6"
        textAlign="center"
        color="primary"
        fontWeight="bolder"
        pb={theme.spacing(4)}
        fontSize="1.25rem"
      >
        {title}
      </Typography>
      <Box
        sx={{
          [theme.breakpoints.down("sm")]: { width: "90%" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "center",
          bgcolor: "common.white",
          boxShadow: 5,
          borderRadius: 2,
          margin: "0 auto",
          p: theme.spacing(4),
          width: "32rem",
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down("sm")]: { width: "4rem", height: "4rem" },
            width: "6rem",
            height: "6rem",
            display: "flex",
          }}
        >
          {iconLeft}
        </Box>
        <Box
          sx={{ [theme.breakpoints.down("sm")]: { width: "80%" }, width: "23rem", margin: "auto" }}
        >
          <Typography
            onClick={callbackFirstLine}
            sx={{
              color: "primary.main",
              fontSize: "1.25rem",
              fontWeight: "bold",
              p: `0 0 ${theme.spacing(0)} ${theme.spacing(4)}`,
              [theme.breakpoints.down("sm")]: { fontSize: "1rem" },
              cursor: callbackFirstLine ? "pointer" : undefined,
            }}
          >
            {paragraphs[0]}
          </Typography>
          {paragraphs.length > 1 &&
            paragraphs.slice(1).map((paragraph) => (
              <Typography
                key={paragraph}
                sx={{
                  fontSize: "1rem",
                  [theme.breakpoints.down("sm")]: { fontSize: ".75rem" },
                  color: "primary.main",
                  p: `0 0 0 ${theme.spacing(4)}`,
                }}
              >
                {paragraph}
              </Typography>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GenericSolutionCard;
